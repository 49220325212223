import React from "react"
import Layout from "../../Components/layout"
import SEO from "../../Components/SEO"

export default function About() {
  return (
    <Layout>
      <SEO title="Refund Policy" description="Refund Policy"></SEO>
      <div className="policy">
        <h1>REFUND POLICY</h1>
        <p>
          No returns and refunds are allowed. Not unless of the following
          situations stated below:
        </p>
        <p>
          Item is defective upon delivery. If the item/s purchased falls under
          the 1-year warranty services offered by Fastlink then a replacement
          will be viable within 7 days of notice upon receipt of the item. Your
          item must be in the same condition that you received it, unworn or
          unused, with tags, and in its original packaging. You’ll also need the
          receipt or proof of purchase.
        </p>
        <p>
          To start a the replacement process of your item, you can contact us at
          inquiry@fastlink.ph You will then receive in email back from us
          containing the instructions on how and where to send your package.
          Items sent back to us without first requesting a replacement will not
          be accepted.
        </p>
        <p>
          Please inspect your order upon receipt and contact us immediately if
          the item you received is the wrong item, so that we can evaluate the
          issue and impose the proper measures for resolution.
        </p>
        <p>We do not accept returns on sale items or gift cards.</p>
        <p>Cancellations are allowed after 1-2 days from placing the order.</p>
        <p>
          You can always contact us for any questions at inquiry@fastlink.ph
        </p>
      </div>
    </Layout>
  )
}
